import { SortOrderResult } from './type'

export enum ENCODING_TYPE {
  NONE = '',
  AES = 'aes',
  DES = 'des',
  BASE_64 = 'base64',
  RABBIT = 'rabbit',
  RC4 = 'rc4',
}
export enum ADMIN_ROLES {
  SUPER_ADMIN = 'SuperAdmin',
  SUB_ADMIN = 'SubAdmin',
  PUBLISHER = 'PUBLISHER',
}

export type AdminRole =
  | ADMIN_ROLES.SUPER_ADMIN
  | ADMIN_ROLES.SUB_ADMIN
  | ADMIN_ROLES.PUBLISHER

export enum SUPPORTED_NETWORKS {
  ETHEREUM = 'Ethereum',
  SOLANA = 'Solana',
}
export enum SUPPORTED_CHAIN {
  ETH = 'ETH',
  SOL = 'SOL',
  BSC = 'BSC',
  POLKADOT = 'POLKADOT',
  POLYGON = 'POLYGON',
}
export enum NOT_SUPPORT_CHAIN {
  NOT_SUPPORTED = 'NOT_SUPPORTED ',
}

export enum EVENT_TYPE {
  SALE = 'SALE',
  LIST = 'LIST',
  OFFER = 'OFFER',
  TRANSFER = 'TRANSFER',
  CANCEL = 'CANCEL',
  MINT = 'MINT',
}

export enum EVENT_SUB_TYPE {
  STANDARD_SALE = 'standard-sale',
  ENGLISH_AUCTION_SALE = 'english-auction-sale',
  DUTCH_AUCTION_SALE = 'dutch-auction-sale',
}

export enum ASSET_SOURCE {
  XSTRELA = 'xStrela',
  OPENSEA = 'opensea.io',
}

export enum GAME_STATUS {
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
  PENDING = 'Pending',
}

export enum GAME_DETAIL_TABS {
  OVERVIEW = 'overview',
  ASSETS = 'assets',
  ACTIVE_LISTING = 'active-listing',
  REVIEWS = 'reviews',
  ACTIVITY = 'activity',
  STATS = 'stats',
  OFFER_RECEIVED = 'offer-received',
  USER_ACTIVITY = 'user-activity',
  QUEST = 'quest',
}

export enum STARTER_PACK_TABS {
  CREATED_PACKS = 'Created Packs',
  CLAIMED_PACKS = 'Claimed Packs',
}
export enum SUBSCRIPTION_TABS {
  FEATURES = 'features',
  ENTITY = 'entity',
}
export enum STATIC_CONTENT {
  TERMS = 'TC',
  PRIVACY = 'PRIVACY',
  GENERAL = 'GENERAL',
}

export enum MEDIA_TYPES {
  GAME_CATEGORY = 'GAME_CATEGORY',
  BLOG = 'BLOG',
  EDUCATION = 'EDUCATION',
  NOTIFICATION = 'NOTIFICATION',
}

export enum QUEST_TYPE {
  CATEGORY = 'EVERGREEN',
  GENERAL = 'GENERAL',
}

export enum SCRIPT_TYPE {
  GOOGLE = 'GOOGLE',
  HUBSPOT = 'HUBSPOT',
}

export enum USER_TYPE {
  ALL = 'ALL',
  PLAYER = 'PLAYER',
  PUBLISHER = 'PUBLISHER',
}

export enum LAUNCHPAD_TYPES {
  LAUNCHPAD = 'LAUNCHPAD',
  STARTER_PACK = 'STARTER_PACK',
  QUEST_STARTER_PACK_AWARDS = 'QUEST_STARTER_PACK_AWARDS',
  QUEST_DIGITAL_AWARDS = 'QUEST_DIGITAL_AWARDS',
  QUEST_AVATAR_AWARDS = 'QUEST_AVATAR_AWARDS',
  QUEST_BADGE_AWARDS = 'QUEST_BADGE_AWARDS',
}
export type mediaTypes =
  | MEDIA_TYPES.GAME_CATEGORY
  | MEDIA_TYPES.BLOG
  | MEDIA_TYPES.EDUCATION
  | MEDIA_TYPES.NOTIFICATION
  | undefined

export enum QUEST_REWARD_TYPES {
  QUEST_STARTER_PACK_AWARD = 'QUEST_STARTER_PACK_AWARD',
  QUEST_NFT_AWARD = 'QUEST_NFT_AWARD',
  QUEST_BADGE_AWARD = 'QUEST_BADGE_AWARD',
  QUEST_PHYSICAL_AWARD = 'QUEST_PHYSICAL_AWARD',
  QUEST_AVATAR_TRAIT_AWARD = 'QUEST_AVATAR_TRAIT_AWARD',
}

export enum HISTORICAL_EVENTS {
  FROM_BEGINNING = 'FROM_BEGINNING',
  FROM_START = 'FROM_START',
  MUST_OPT_IN = 'MUST_OPT_IN',
}

export enum TIME_TYPE {
  EVERGREEN = 'EVERGREEN',
  TIME_CONSTRAINT = 'TIME_CONSTRAINT',
}

export enum QUEST_APPROVAL_STATUS {
  APPROVED = 'APPROVED',
  NONE = 'NONE',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
  CHANGE_REQUEST = 'CHANGE_REQUEST',
}

export enum QUEST_EVENT_TYPE {
  PARENT = 'PARENT',
  CHILD = 'CHILD',
}

export enum LAUNCHPAD_TYPE {
  LAUNCHPAD = 'LAUNCHPAD',
  STARTER_PACK = 'STARTER_PACK',
  QUEST_STARTER_PACK_AWARDS = 'QUEST_STARTER_PACK_AWARDS',
  QUEST_DIGITAL_AWARDS = 'QUEST_DIGITAL_AWARDS',
  QUEST_AVATAR_AWARDS = 'QUEST_AVATAR_AWARDS',
  QUEST_BADGE_AWARDS = 'QUEST_BADGE_AWARDS',
  GAME_LAUNCHPAD = 'GAME_LAUNCHPAD',
  REWARD_LAUNCHPAD = 'REWARD_LAUNCHPAD',
}

export enum CHART_DURATION {
  CHART_24HRS = 'CHART_24HRS',
  CHART_7D = 'CHART_7D',
  CHART_30D = 'CHART_30D',
  CHART_3M = 'CHART_3M',
  CHART_1YR = 'CHART_1YR',
  CHART_ALL_YR = 'CHART_ALL_YR',
}

export enum QUEST_SORT_OPTIONS {
  RECENTLY_CREATED = 'RECENTLY_CREATED',
  OLDEST = 'OLDEST',
  STARTED_ASC = 'STARTED_ASC',
  STARTED_DESC = 'STARTED_DESC',
}

export enum QUEST_STATUS {
  ACTIVE = 'ACTIVE',
  LIVE = 'LIVE',
  UPCOMING = 'UPCOMING',
  ENDED = 'ENDED',
}

export enum QUEST_REWARDS_TYPE {
  QUEST_BADGE_AWARD = 'QUEST_BADGE_AWARD',
  QUEST_NFT_AWARD = 'QUEST_NFT_AWARD',
  QUEST_AVATAR_TRAIT_AWARD = 'QUEST_AVATAR_TRAIT_AWARD',
  QUEST_PHYSICAL_AWARD = 'QUEST_PHYSICAL_AWARD',
  QUEST_STARTER_PACK_AWARD = 'QUEST_STARTER_PACK_AWARD',
  QUEST_CATEGORY_PROGRESSION_AWARD = 'QUEST_CATEGORY_PROGRESSION_AWARD',
}

export enum QUEST_PROCESS_TYPE {
  FROM_BEGINNING = 'FROM_BEGINNING',
  FROM_START = 'FROM_START',
  MUST_OPT_IN = 'MUST_OPT_IN',
}

export enum PUBLISHER_LAUNCHPAD_STATUS {
  AWAITING = 'AWAITING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  VERIFIED = 'VERIFIED',
  LIVE = 'LIVE',
  UPCOMMING = 'UPCOMING',
  ENDED = 'ENDED',
  ENDS_SOON = 'ENDS_SOON',
  QUEST_COMPLETED = 'QUEST_COMPLETED',
}

export enum ADMIN_ROLE {
  SUBADMIN = 'SubAdmin',
  SUPERADMIN = 'SuperAdmin',
}

export enum ORDER_TYPE {
  STANDARD_SALE = 'STANDARD_SALE',
  ENGLISH = 'ENGLISH',
  DUTCH = 'DUTCH',
  BULK = 'BULK',
}

export enum BANNER_SLIDER_PATTERN {
  ARROW = 'ARROW',
  DOT = 'DOT',
  IMAGE = 'IMAGE',
}

export enum MEDIA_TYPE {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
}
export enum BANNER_SLIDER_TYPE {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  TEXT = 'TEXT',
  SCRIPT = 'SCRIPT',
}

export enum CONTACT_TYPE {
  GENERAL = 'GENERAL',
  SUPPORT = 'SUPPORT',
}
export enum ASSET_SORT_OPTIONS {
  PRICE_LOW_TO_HIGH = 'PRICE_LOW_TO_HIGH',
  PRICE_HIGH_TO_LOW = 'PRICE_HIGH_TO_LOW',
  RECENTLY_ADDED = 'RECENTLY_ADDED',
  HIGHEST_LAST_SALE = 'HIGHEST_LAST_SALE',
  RECENTLY_CREATED = 'RECENTLY_CREATED',
  MOST_VIEWED = 'MOST_VIEWED',
  OLDEST = 'OLDEST',
  MOST_FAVORITED = 'MOST_FAVORITED',
  ENDING_SOON = 'ENDING_SOON',
  BEST_OFFER = 'BEST_OFFER',
}

export enum WEB_CAROUSEL_STATUS {
  APPROVED = 'APPROVED',
  LIVE = 'LIVE',
  REJECTED = 'REJECTED',
  ENDED = 'ENDED',
  PENDING = 'PENDING',
  UPCOMING = 'UPCOMING',
  CHANGE_REQUEST = 'CHANGE_REQUEST',
}

export enum REPORT_EXPORT_FILE_EXTENSION {
  CSV = 'CSV',
  XLS = 'XLSX',
}

export enum SOCIAL_MEDIA {
  GOOGLE = 'GOOGLE',
  META = 'META',
  INSTAGRAM = 'INSTAGRAM',
  TWITTER = 'TWITTER',
  DISCORD = 'DISCORD',
}

export enum WALLET_TYPE {
  PHANTOM = 'PHANTOM',
  META_MASK = 'META_MASK',
  COIN_BASE = 'COIN_BASE',
  WALLET_CONNECT = 'WALLET_CONNECT',
}
export enum REPORTS {
  USER_REPORT = 'USER_REPORT',
  ACTIVITY_REPORT = 'ACTIVITY_REPORT',
}

export enum USER_LOGIC_GROUP_SORT {
  CREATED_ASC = 'CREATED_ASC',
  CREATED_DESC = 'CREATED_DESC',
  EVENT_TYPE_ID_ASC = 'EVENT_TYPE_ID_ASC',
  EVENT_TYPE_ID_DESC = 'EVENT_TYPE_ID_DESC',
}

export enum CAROUSEL_TYPE {
  FLOATING = 'FLOATING',
  GENERAL = 'GENERAL',
}

export enum CATEGORY_TYPE {
  GAME = 'GAME',
  EDUCATIONAL = 'EDUCATIONAL',
  PLATFORM_SUPPORT = 'PLATFORM_SUPPORT',
  THEMES = 'THEMES',
  GAME_MODE = 'GAME_MODE',
  INTERACTIVE_ELEMENTS = 'INTERACTIVE_ELEMENTS',
  CONTENT_DESCRIPTOR = 'CONTENT_DESCRIPTOR',
  AGE_RATING = 'AGE_RATING',
  PEGI_RATING = 'PEGI_RATING',
}

export type SORT_ORDER_TYPES = '' | 'asc' | 'desc'

export type GetSortOrder = (
  currentOrder: string,
  ascOrder: string | boolean,
  descOrder: string | boolean
) => SortOrderResult

export enum SYSTEM_CONFIG_FUNCTIONALITY_STATUS {
  UNIMPLEMENTED = 'Unimplemented',
  PENDING = 'Pending',
  IMPLEMENTED = 'Implemented',
}

export enum SYSTEM_CONFIG_ACCESSIBILITY {
  CREATE = 'Create',
  READ = 'Read',
  UPDATE = 'Update',
  DELETE = 'Delete',
}
export enum SYSTEM_CONFIG_DATA_TYPES {
  UNSIGNED_INT = 'UnsignedInt',
  INT = 'Int',
  FLOAT = 'Float',
  VARCHAR = 'Varchar',
  TIMESTAMP = 'Timestamp',
  TIMEZONE = 'Timezone',
  TIME = 'Time',
  JSON = 'Json',
  BOOL = 'Bool',
}

export enum SUbSCRIPTION_ENTITY {
  INDIVIDUAL = 'INDIVIDUAL',
  GROUP = 'GROUP',
  SYSTEM = 'SYSTEM',
}
export enum LIST_TYPE {
  LIST = 'LIST',
  MULTI_CHECK = 'MULTI_CHECK',
  RADIO = 'RADIO',
}

export enum EDUCATION_LIST_SORT {
  TITLE_ASC = 'TITLE_ASC',
  TITLE_DESC = 'TITLE_DESC',
  START_TIME_ASC = 'START_TIME_ASC',
  START_TIME_DESC = 'START_TIME_DESC',
  END_TIME_ASC = 'END_TIME_ASC',
  END_TIME_DESC = 'END_TIME_DESC',
}

export enum EDUCATION_STATUS {
  LIVE = 'LIVE',
  UPCOMING = 'UPCOMING',
  ENDED = 'ENDED',
  DRAFT = 'DRAFT',
}

export enum USER_PERSONA {
  INDIVIDUAL = 'Individual',
  GROUP = 'Group',
  SUBSCRIPTION = 'Subscription',
}

export enum TIME_SETTING {
  TIME_BASED = 'TimeBased',
  EVERGREEN = 'EverGreen',
}
export enum AGE_RATING {
  ESRB = 'ESRB',
  PEGI = 'PEGI',
}
